@import url('https://fonts.googleapis.com/css?family=Lato|Noto+Sans+JP&display=swap');

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Lato', 'Noto Sans JP', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* color */
  --color-white: #ffffff;
  --color-bitkey: #029fac;
  --color-key: #03a9f4;
  --color-admin-key: #2f80ed;
  --color-error: #e91e63;
  --color-sub: #f29700;
  --color-gray-1: #333333;
  --color-gray-2: #4f4f4f;
  --color-gray-3: #828282;
  --color-text: var(--color-gray-1);
  --color-background-gray: #eceff1;

  /* background */
  --background-error: linear-gradient(
      0deg,
      rgba(233, 30, 99, 0.07),
      rgba(233, 30, 99, 0.07)
    ),
    #ffffff;
  --background-admin: #f3f8ff;
}
